.title{
  font-family: "Andale Mono", monospace;
  font-weight: bolder;
  text-decoration: none;
  transition-duration: 500ms;
  font-size: large;
  letter-spacing: 2px;
  color:  aliceblue;
  box-shadow:inset 0 -0.4em 0 #5f5f5f;
  margin-left: 1rem;
  padding-left: 0rem;
}

.dropdown{
  font-family: "Andale Mono", monospace;
  font-weight:bolder;
  transition-duration: 500ms;
  text-decoration: none;
  font-size: large;
  color: aliceblue;
  box-shadow: inset 0 -0.4em 0 #5f5f5f;
}

.tab{
  font-family: "Andale Mono", monospace;
  font-weight:bolder;
}

.dropdown:hover{
  transition-duration: 500ms;
  color: aliceblue;
  font-size:larger;
}

.title:hover{
  transition-duration: 500ms;
  font-size:larger;
  color: aliceblue;
}
.icon{
  padding: 0.1rem;
  padding-top: 0.3rem;
}

.navSocial{
  width: 35px;
  transition: transform 1.2s; /* Apply transition to the transform property */
}

.navSocialLinkedin{
  width: 35px;
  transition: transform 1s; /* Apply transition to the transform property */
}


.navSocialGmail{
  width: 35px;
  transition: transform 0.5s; /* Apply transition to the transform property */
}
.navSocialGmail:hover {
  transform: translateY(-0.2rem);
  
}
.navSocialLinkedin:hover {
  transform:scale(1.2);
  
}

.navSocial:hover {
  transform:rotateZ(360deg);
}
@media (prefers-reduced-motion: prefer-reduced-motion) {
  .navSocialGmail:hover {
    transform: none;
  }

  .navSocialLinkedin:hover {
    transform: none;
  }

  .navSocial:hover {
    transform: none;
  }
  
}


@media (max-width: 800px) {
  .title{
    font-size: 90%;
  }


  .navSocial{
    width: 28px;
  }

  .navSocialLinkedin{
    width: 28px;
  }
  .navSocialGmail{
    width: 28px;
  }

  .dropdown:hover{
    transition-duration: 500ms;
    color: aliceblue;
    font-size:small;
  }
  
  .title:hover{
    transition-duration: 500ms;
    font-size:small;
    color: aliceblue;
  }

}

@media (max-width: 576px) {
  .icon{
    padding: 0rem;
  }

  .title{
    font-size: 75%;
    padding: 0rem;
    margin: 0rem;
  }

  .dropdown{
    font-size: 90%;
  }

  .tab{
    font-size: 75%;
  }
  .navSocial{
    width: 18px;
  }

  .navSocialLinkedin{
    width: 18px;
  }
  .navSocialGmail{
    width: 18px;
  }

}

