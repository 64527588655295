.iconbox {
  display: block;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.iconbox:hover {
  transition-duration: 500ms;
  color: aliceblue;
  max-width: 100%;
}

.h1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  font-size: 250%;
}

.h2 {
  font-size: 400%;
  color: #eefbfb;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

@media (max-width: 1200px) {
  .h2 {
    font-size: 400%;
  }

  .h1 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    font-size: 250%;
  }

  .iconbox {
    display: block;
    margin-bottom: 2rem;
    margin-top: 0rem;
  }
}

@media (max-width: 576px) {
  .h2 {
    font-size: 200%;
    margin-top: 0.6rem;
  }

  .h1 {
    font-size: 100%;
  }

  .iconbox {
    display: block;
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
}