/**/
.container{
  overflow-x: hidden;
  overflow-y: hidden;
}


.container:before {
  display: block;
  position: absolute;
  left: 0;
  top: 4rem;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  /* background-image: url('../components/Elements/Slide/photos/misc/Subject.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size:65rem; */
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.containerFluid {
  display:flex;
	clear: both;
  flex-wrap: wrap;
	padding-left: 2rem;
	padding-right: 2rem;
	margin: 0;
}
.each-slide-effect>div {
  display: flex;
  border-radius: 10px 10px;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 1000px;
  text-align: center;
}

.each-slide-effect a {
  font-size: 20px;
  text-align: center;
  width: 50%;

  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  backface-visibility: hidden;
  display: block;
}

ol{
  padding-left: 30px;
}

li{
  padding-right: 4%;
  font-family: 'Consolas';
  color: #535353;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-color: #2c2c2c;
}

.iconbox{
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.timeline_container {
  width: 100%;
}

.timeline {
  position: relative;
  padding: 0;
  margin-top: 0.0rem;
}

.timeline_item {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.timeline_item::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 3px; /* Adjust the thickness of the line */
  background-color: #eff7ff; /* Color of the line */
  z-index: -1;
}

.timeline_content {
  border-color: 10px solid #eff7ff;
  background-color: #000000;
  border-radius: 5px;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
}

.timeline_date {
  font-family: "Andale Mono", monospace;
  font-style: italic;
  font-size: small;
}


.cursor{
  color: white;
}

.mainHeader{
  font-family: "DejaVu Sans Mono", monospace;
  color:   #eff7ff;
  font-weight: 550;
  font-size: 500%;
}

.gallery{
  font-weight: 600;
  font-size: 200%;
  margin-bottom: 4rem;
}
.galleryClick{
  width: 15rem;
  height: 15rem;
  opacity: 1;
}

.arrow {
  width: 100px;
  color: rgb(95, 95, 95);
  padding-bottom: 10rem;
}


.rocketryIcon{
  width: 18rem;
  padding: auto;
  filter: grayscale(100%);
  transition: transform 0.5s; /* Apply transition to the transform property */
}

.rocketryIcon:hover{
  transition-duration: 500ms;
  filter: grayscale(0%);
  filter: drop-shadow(0 0 0.75rem #f0f0f0);
  color: aliceblue;
  transform: scale(0.9); /* Scale down the image on hover */
}
/* .uwaterlooIcon{
  filter: grayscale(100%);
  transition: filter 0.3s ease;
  width: 5rem;
  transition: transform 0.5s; /* Apply transition to the transform property 
} 
*/

/* .uwaterlooIcon:hover{
  transition-duration: 500ms;
  filter: grayscale(0%);
  filter: drop-shadow(0 0 0.75rem #f0f0f0);
  color: aliceblue;
  transform: scale(0.9); /* Scale down the image on hover 
} */

.goodlabsIcon{
  width: 13rem;
  transition: transform 0.5s; /* Apply transition to the transform property */
}

.goodlabsIcon:hover{
  transition-duration: 500ms;
  filter: grayscale(0%);
  filter: drop-shadow(0 0 0.75rem #f0f0f0);
  color: aliceblue;
  transform: scale(0.9); /* Scale down the image on hover */
}

.watoIcon{
  width: 12.5rem;
  border-radius: 1.5rem;
  transition: transform 0.5s; /* Apply transition to the transform property */
}

.watoIcon:hover{
  transition-duration: 500ms;
  filter: grayscale(0%);
  filter: drop-shadow(0 0 0.75rem #f0f0f0);
  color: aliceblue;
  transform: scale(0.9); /* Scale down the image on hover */
}
.frcIcon{
  filter: grayscale(100%);
  width: 13rem;
  padding-top: 2rem;
  transition: transform 0.5s; /* Apply transition to the transform property */
}

.frcIcon:hover{
  transition-duration: 500ms;
  filter: grayscale(0%);
  filter: drop-shadow(0 0 0.75rem #f0f0f0);
  color: aliceblue;
  transform: scale(0.9); /* Scale down the image on hover */
}

/*
For mobile
*/
@media (max-width: 1200px) {
  .title{
    font-size: 1rem;
  }
  .profile{
    margin-top: 0px;
    margin-bottom: 11px;
    width: 150px;
  }
  .mainHeader{
    font-weight: 550;
    font-size: 400%;
  }
  .arrow {
    width: 90px;
    opacity: 0.6;
  }
  .gallery{
    font-weight: 600;
    font-size: 150%;
  }
} 

@media (max-width: 768px) {
  .timeline_date {
    font-size: x-small;
  }
  .container::before{
    top: 3.5rem;
  }
  .rocketryIcon{
    width: 17rem;
  }
  .goodlabsIcon{
    width: 12rem;
  }
  .watoIcon{
    width: 12rem;
  }
  .frcIcon{
    padding-top: 1rem;
  }
  .mainHeader{
    font-weight: 550;
    font-size: 200%;
  }
}

@media (max-width: 576px) {
  .timeline_content {
    padding: 0.5rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
  
  .timeline_date {
    font-size: xx-small;
  }

  .title{
    font-size: smaller;
  }
  .skillText{
    margin-top: 0rem;
    
  }

  .rocketryIcon{
    width: 9rem;
    padding: 0rem;
  }
  
  .uwaterlooIcon{
    width: 2rem;
  }
  
  .frcIcon{
    width: 8rem;
    padding-top: 0rem;
  }
  .goodlabsIcon{
    width: 6rem;
  }
  .watoIcon{
    width: 6rem;
  }
  
}