.each-slide-effect>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 800px;
    text-align: center;
}

@media (max-width: 992px) {
    .each-slide-effect>div {
    height: 570px;
    }
}

.caption a {
    overflow: hidden;
    font-size: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-height: 2.7rem;
    padding: 10px;
    font-family: "Helvetica Neue", sans-serif;
    backface-visibility: hidden;
    display: block;
    opacity: 1;
}

.caption {
    border-radius: 10px 10px;
    color: white;
    text-decoration: none;
    text-align: center;
    width: max-content;
    margin: auto;
}

.caption a:hover {
    color: white;
    text-align: center;
    margin: auto;
}


button {
    margin-left: 20px;
    margin-right: 20px;
    padding-right: 0;
    padding-left: 0;
    resize: both;
    scale: 150%;
}

.photofrc1 {
    background-image: url('./photos/FRC/2022 Robot.JPG');
    background-position: 90% 80%;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.photofrc2 {
    background-image: url('./photos/FRC/IMG_2781.jpeg');
    width: 550px;
    margin-left: auto;
    margin-right: auto;
}

.photofrc3 {
    background-image: url('./photos/FRC/IMG_3426.JPG');
    width: 600px;
    background-position: 70% 80%;
    margin: auto;
}

.photofrc4 {
    background-image: url('./photos/FRC/OpenMV Cam on Robot.jpeg');
    width: 400px;
    margin: auto;
    background-position: 50% 50%;
}

.photofrc5 {
    background-image: url('./photos/FRC/Programming.jpg');
    width: 100%;
    margin: auto;
    background-position: 50% 90%;
}

.photofrc6 {
    background-image: url('./photos/FRC/2022_Practice.jpg');
    width: 100%;
    margin: auto;
    background-position: 50% 90%;
}

.photofrc7 {
    background-image: url('./photos/FRC/2018Comp.jpg');
    width: 350px;
    margin: auto;
    background-position: 50% 90%;
}

.photofrc8 {
    background-image: url('./photos/FRC/2019RobotTesting.jpg');
    width: 100%;
    margin: auto;
    background-position: 50% 90%;
}

/*
Go kart
*/
.photokart1 {
    background-image: url('./photos/gokart/IMG_1418.jpeg');
    max-width: 900px;
    background-position: 60% 50%;
    margin: auto;
}

.photokart2 {
    background-image: url('./photos/gokart/IMG_1424.jpeg');
    max-width: 900px;
    background-position: 60% 50%;
    margin: auto;
}

.photokart3 {
    background-image: url('./photos/gokart/IMG_1460_Moment.jpg');
    background-position: 60% 50%;
}

.photokart4 {
    background-image: url('./photos/gokart/IMG_1470.jpeg');
    width: 40%;
    background-position: 60% 50%;
    margin: auto;
}

.photokart5 {
    background-image: url('./photos/gokart/IMG_5826.PNG');
    width: 40%;
    background-position: 60% 50%;
    margin: auto;
}

.photokart6 {
    background-image: url('./photos/misc/welding.JPG');
    width: 100%;
    max-width: 800px;
    background-position: 60% 50%;
    margin: auto;
}

/*
Hand Sanitizer
*/
.photosanitizer {
    background-image: url('./photos/handSanitizer/IMG_2949.jpeg');
    width: 470px;
    margin: auto;
    background-position: 50% 50%;
}

.photosanitizer1 {
    background-image: url('./photos/handSanitizer/IMG_2912.jpeg');
    width: 60%;
    margin: auto;
    background-position: 50% 50%;
}

.photosanitizer2 {
    background-image: url('./photos/handSanitizer/IMG_2952.jpeg');
    width: 470px;
    margin: auto;
    background-position: 50% 50%;
}

.photosanitizer3 {
    background-image: url('./photos/handSanitizer/IMG_2960.jpeg');
    width: 470px;
    margin: auto;
    background-position: 50% 50%;
}


.photomarket {
    background-image: url('./photos/misc/marketalarm.PNG');
    max-width: 1500px;
    margin: auto;
}



/*
Robot Project 1A
*/
.photorobot1a {
    background-image: url('./photos/1AProject/robot1A.jpg');
    background-position: 20% 60%;
}

.photorobot1a2 {
    background-image: url('./photos/1AProject/20221201_193342.jpg');
    background-position: 40% 90%;
}

.photorobot1a3 {
    background-image: url('./photos/1AProject/20221201_192840.jpg');
    background-position: 50% 90%;
}

/*
Automatic Plant Watering
*/
.photoplant1 {
    background-image: url('./photos/plantWaterer/IMG_3518.jpeg');
    background-position: 0% 60%;
    width: 470px;
    margin: auto;
}

.photoplant2 {
    background-image: url('./photos/plantWaterer/IMG_3525.jpeg');
    background-position: 0% 30%;
    width: 470px;
    margin: auto;
}

.photoplant3 {
    background-image: url('./photos/plantWaterer/IMG_3641_Moment.jpg');
    background-position: 00% 70%;
    width: 100%;
    margin: auto;
}

.photoplant4 {
    background-image: url('./photos/plantWaterer/IMG_3641_Moment.jpg');
}

.photoplant5 {
    background-image: url('./photos/plantWaterer/IMG_3645.JPG');
}

/*
RC planes
*/
.photoplane1 {
    background-image: url('./photos/RCplanes/1stplane.JPG');
    background-position: 50% 70%;
}

.photoplane2 {
    background-image: url('./photos/RCplanes/2ndplane.JPG');
    background-position: 50% 80%;
    width: 460px;
    margin: auto;
}

.photoplane3 {
    background-image: url('./photos/RCplanes/IMG_0197.JPG');
    background-position: 50% 80%;
    width: 460px;
    margin: auto;
}

.photoplane4 {
    background-image: url('./photos/RCplanes/IMG_3098.jpeg');
    background-position: 50% 50%;
    max-width: 900px;
    margin: auto;
}

.photoplane5 {
    background-image: url('./photos/RCplanes/IMG_3101.jpeg');
}

.photoplane6 {
    background-image: url('./photos/RCplanes/latest1.jpeg');
}

.photoplane7 {
    background-image: url('./photos/RCplanes/moment.jpg');
    background-position: 50% 80%;
}

.photoplane8 {
    background-image: url('./photos/RCplanes/IMG_6971.jpeg');
    background-position: 50% 80%;
    max-width: 1200px;
    margin: auto;
}

/*
RPi Laptop
*/
.photolaptop1 {
    background-image: url('./photos/RPiLaptop/IMG_6924.jpeg');
    background-position: 60% 80%;
    width: 470px;
    margin: auto;
}

.photolaptop2 {
    background-image: url('./photos/RPiLaptop/IMG_6923.jpeg');
    background-position: 60% 50%;
    width: 470px;
    margin: auto;
}

/*
Openmv
*/
.photoopenmv {
    background-image: url('./photos/FRC/2020\ Robot\ Ball\ Detection.jpg');
    background-position: 50% 50%;
    width: 600px;
    margin: auto;
}


.photoopenmv3 {
    background-image: url('./photos/FRC/IMG_3428.jpeg');
    background-position: 30% 50%;
    width: 600px;
    margin: auto;
}

/*
Toyota Innovation
*/
.phototoyota {
    background-image: url('./photos/ToyotaChallenge/Capture.PNG');
    background-position: 50% 50%;
    max-width: 1000px;
    width: 100%;
    margin: auto;
}

.phototoyota1 {
    background-image: url('./photos/ToyotaChallenge/Capture1.PNG');
    background-position: 50% 50%;
    max-width: 1000px;
    width: 100%;
    margin: auto;
}

.phototoyota2 {
    background-image: url('./photos/ToyotaChallenge/Capture3.PNG');
    background-position: 50% 50%;
    max-width: 1000px;
    width: 100%;
    margin: auto;
}

.phototoyota3 {
    background-image: url('./photos/ToyotaChallenge/CodeSnippet.png');
    background-position: 0% 50%;
    max-width: 1000px;
    width: 100%;
    margin: auto;
}

.phototoyota4 {
    background-image: url('./photos/ToyotaChallenge/file.jpg');
    background-position: 100% 50%;
    max-width: 1000px;
    width: 100%;
    margin: auto;
}
.phototoyota5 {
    background-image: url('./photos/ToyotaChallenge/output.png');
    background-position: 100% 50%;
    max-width: 1000px;
    width: 100%;
    margin: auto;
}
.phototoyota6 {
    background-image: url('./photos/ToyotaChallenge/output2.png');
    background-position: 100% 50%;
    max-width: 1000px;
    width: 100%;
    margin: auto;
}
/*
UofT hacks
*/
.photouofthacks {
    background-image: url('./photos/UofTHacks/original.jpg');
    background-position: 30% 50%;
    width: 59%;
    max-width: 400px;
    margin: auto;
}

.photouofthacks1 {
    background-image: url('./photos/UofTHacks/gallery.jpg');
    background-position: 30% 50%;
    width: 59%;
    max-width: 400px;
    margin: auto;
}

/*
hack the 6ix
*/
.photohackthe6ix {
    background-image: url('./photos/Hackthe6ix/IMG_0072.jpg');
    background-position: 30% 50%;
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.photohackthe6ix1 {
    background-image: url('./photos/Hackthe6ix/IMG_8106.jpeg');
    background-position: 30% 50%;
    max-width: 900px;
    margin: auto;
}

.photohackthe6ix2 {
    background-image: url('./photos/Hackthe6ix/IMG_8108.jpeg');
    background-position: 30% 50%;
    width: 80%;
    max-width: 700px;
    margin: auto;
}

.photohackthe6ix3 {
    background-image: url('./photos/Hackthe6ix/WhatsApp\ Image\ 2023-08-26\ at\ 12.57.50\ AM.jpeg');
    background-position: 30% 50%;
    width: 79%;
    max-width: 800px;
    margin: auto;
}

.photouofthacksXI {
    background-image: url('./photos/UoftHacksXI/Screenshot 2024-01-29 132515.png');
    background-position: 90% 0%;
    background-size: contain;
    margin: auto;
}

.photouofthacksXI2 {
    background-image: url('./photos/UoftHacksXI/Screenshot 2024-01-29 133055.png');
    background-position: 90% 0%;
    background-size: contain;
    width: 100%;
    height: 100%;
    margin: auto;
}

.photouofthacksXI3 {
    background-image: url('./photos/UoftHacksXI/Screenshot 2024-01-29 133339.png');
    background-position: 50% 0%;

    margin: auto;
}

/*
Makeuoft
*/

.photomakeuoft {
    background-image: url('./photos/makeuoft/hat.jpeg');
    background-position: 50% 50%;
    width: 100%;
    max-width: 1400px;
    background-size: contain;
    margin: auto;
}

.photomakeuoft2 {
    background-image: url('./photos/makeuoft/wearing_hat.jpeg');
    background-position: 50% 80%;
    width: 100%;
    max-width: 800px;
    background-size: contain;
    margin: auto;
}

.photomakeuoft3 {
    background-image: url('./photos/makeuoft/electronics.jpeg');
    background-position: 50% 50%;
    width: 100%;
    max-width: 800px;
    background-size: contain;
    margin: auto;
}

.photomakeuoft4 {
    background-image: url('./photos/makeuoft/right_pov.jpeg');
    background-position: 50% 50%;
    width: 100%;
    max-width: 1200px;
    background-size: contain;
    margin: auto;
}

/*
Goodlabs
*/

.photogoodlabs1 {
    background-image: url('./photos/goodlabs/robbie9_2024-front.png');
    background-position: 50% 50%;
    width: 100%;
    max-width: 1000px;
    background-size: contain;
    margin: auto;
}

.photogoodlabs2 {
    background-image: url('./photos/goodlabs/robbie9_2024-front2.png');
    background-position: 50% 50%;
    width: 100%;
    max-width: 1200px;
    background-size: contain;
    margin: auto;
}

.photogoodlabs3 {
    background-image: url('./photos/goodlabs/robbie9_2024-side.png');
    background-position: 50% 50%;
    width: 100%;
    max-width: 1200px;
    background-size: contain;
    margin: auto;
}

.photogoodlabs4 {
    background-image: url('./photos/goodlabs/with_robbie.jpeg');
    background-position: 50% 50%;
    width: 100%;
    max-width: 1000px;
    background-size: contain;
    margin: auto;
}

.photogoodlabs5 {
    background-image: url('./photos/goodlabs/robbie_standing.png');
    background-position: 50% 50%;
    width: 100%;
    max-width: 700px;
    background-size: contain;
    margin: auto;
}

.photogoodlabs6 {
    background-image: url('./photos/goodlabs/walking_robbie2.gif');
    background-position: 50% 50%;
    width: 100%;
    max-width: 600px;
    background-size: contain;
    margin: auto;
}

.photogoodlabs7 {
    background-image: url('./photos/goodlabs/walking_robbie.gif');
    background-position: 50% 50%;
    width: 100%;
    max-width: 1400px;
    background-size: contain;
    margin: auto;
}

.photogoodlabs8 {
    background-image: url('./photos/goodlabs/initial_robbie.gif');
    background-position: 50% 50%;
    width: 100%;
    max-width: 1400px;
    background-size: contain;
    margin: auto;
}

.photogoodlabs9 {
    background-image: url('./photos/goodlabs/Robbie_Image.png');
    background-position: 50% 0%;
    max-width: 25rem;
    background-size:contain;
    margin: auto;
}

.photogoodlabs10 {
    background-image: url('./photos/goodlabs/IMG_7961.JPG');
    background-position: 50% 50%;
    max-width: 55rem;
    background-size:contain;
    margin: auto;
}

/*
Hackmit
*/

.photohackmit {
    background-image: url('./photos/HackMIT/mit_dome.jpg');
    background-position: 50% 50%;
    width: 100%;
    max-width: 1000px;
    background-size: contain;
    margin: auto;
}

.photohackmit2 {
    background-image: url('./photos/HackMIT/team.jpg');
    background-position: 50% 80%;
    width: 100%;
    max-width: 1100px;
    background-size: contain;
    margin: auto;
}

.photohackmit3 {
    background-image: url('./photos/HackMIT/axy_main_page.png');
    background-position: 50% 50%;
    width: 100%;
    max-width: 380px;
    background-size: contain;
    margin: auto;
}

.photohackmit4 {
    background-image: url('./photos/HackMIT/axy_calender.png');
    background-position: 50% 50%;
    width: 100%;
    max-width: 380px;
    background-size: contain;
    margin: auto;
}

.photocalhack {
    background-image: url('./photos/CalHacks/IMG_0600.jpeg');
    background-position: 50% 50%;
    width: 100%;
    max-width: 720px;
    background-size: contain;
    margin: auto;
}

.photocalhack2 {
    background-image: url('./photos/CalHacks/IMG_0604.jpeg');
    background-position: 50% 50%;
    width: 100%;
    max-width: 1080px;
    background-size: contain;
    margin: auto;
}

.photocalhack3 {
    background-image: url('./photos/CalHacks/IMG_0588.jpeg');
    background-position: 50% 50%;
    width: 100%;
    max-width: 800px;
    background-size: contain;
    margin: auto;
}

.photocalhack4 {
    background-image: url('./photos/CalHacks/IMG_0606.jpeg');
    background-position: 50% 50%;
    width: 100%;
    max-width: 1000px;
    background-size: contain;
    margin: auto;
}
