.button{
    padding: 0.8rem;
    font-family: "DejaVu Sans Mono", monospace;
    text-align: center;
    margin: auto;
    align-self: center;
  }

  h1 {
    margin-top: 1.5rem;
    color:  #EEFBFB;
    font-size: 300%;
    font-family: "DejaVu Sans Mono", monospace;
    font-weight: bold;
    font-style: normal;
    text-align: center;
  }